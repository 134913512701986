@tailwind base;
@tailwind components;
@tailwind utilities;

/* Variables */
:root {
  --gray-offset: rgba(0, 0, 0, 0.03);
  --gray-border: rgba(0, 0, 0, 0.15);
  --gray-light: rgba(0, 0, 0, 0.4);
  --gray-mid: rgba(0, 0, 0, 0.7);
  --gray-dark: rgba(0, 0, 0, 0.9);
  --body-color: var(--gray-mid);
  --headline-color: var(--gray-dark);
  --accent-color: #0066f0;
  --body-font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  --radius: 6px;
  --form-width: 600px;
}

/* Base */
* {
  box-sizing: border-box;
}
body {
  font-family: var(--body-font-family);
  font-size: 16px;
  color: var(--body-color);
  -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--body-color);
  margin-top: 2px;
  margin-bottom: 4px;
}
h1 {
  font-size: 27px;
  color: var(--headline-color);
}
h4 {
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-light);
}

/* Layout */
.sr-root {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 980px;
  padding: 48px;
  align-content: center;
  justify-content: center;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
}
.sr-header {
  margin-bottom: 32px;
}
.sr-payment-summary {
  margin-bottom: 20px;
}
.sr-main,
.sr-content {
  display: flex;
  justify-content: center;
  height: 100%;
  align-self: center;
}
.sr-main {
  width: var(--form-width);
}
.sr-content {
  padding-left: 48px;
}
.sr-header__logo {
  background-image: var(--logo-image);
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
}
.sr-legal-text {
  color: var(--gray-light);
  text-align: center;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}
.sr-field-error {
  color: var(--accent-color);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}

/* Form */
.sr-form-row {
  margin: 16px 0;
}
label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 8px;
  display: inline-block;
}

/* Inputs */
.sr-input,
.sr-select,
input[type='text'],
input[type='number'] {
  border: 1px solid var(--gray-border);
  border-radius: var(--radius);
  padding: 5px 12px;
  height: 44px;
  width: 100%;
  transition: box-shadow 0.2s ease;
  background: white;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #32325d;
}
.sr-input:focus,
input[type='text']:focus,
button:focus,
.focused {
  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
    0 0 0 4px rgba(50, 151, 211, 0.3);
  outline: none;
  z-index: 9;
}
.sr-input::placeholder,
input[type='text']::placeholder,
input[type='number']::placeholder {
  color: var(--gray-light);
}

/* Checkbox */
.sr-checkbox-label {
  position: relative;
  cursor: pointer;
}

.sr-checkbox-label input {
  opacity: 0;
  margin-right: 6px;
}

.sr-checkbox-label .sr-checkbox-check {
  position: absolute;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: white;
  border: 1px solid var(--gray-border);
  border-radius: 4px;
  transition: all 0.2s ease;
}

.sr-checkbox-label input:focus ~ .sr-checkbox-check {
  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
    0 0 0 4px rgba(50, 151, 211, 0.3);
  outline: none;
}

.sr-checkbox-label input:checked ~ .sr-checkbox-check {
  background-color: var(--accent-color);
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: -1px -1px;
}

/* Select */
.sr-select {
  display: block;
  height: 44px;
  margin: 0;
  background-repeat: no-repeat, repeat;
  background-position: right 12px top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.sr-select::-ms-expand {
  display: none;
}
.sr-select:hover {
  cursor: pointer;
}
.sr-select:focus {
  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
    0 0 0 4px rgba(50, 151, 211, 0.3);
  outline: none;
}
.sr-select option {
  font-weight: 400;
}
.sr-select:invalid {
  color: var(--gray-light);
}

/* Combo inputs */
.sr-combo-inputs {
  display: flex;
  flex-direction: column;
}
.sr-combo-inputs input,
.sr-combo-inputs .sr-select {
  border-radius: 0;
  border-bottom: 0;
}
.sr-combo-inputs > input:first-child,
.sr-combo-inputs > .sr-select:first-child {
  border-radius: var(--radius) var(--radius) 0 0;
}
.sr-combo-inputs > input:last-child,
.sr-combo-inputs > .sr-select:last-child {
  border-radius: 0 0 var(--radius) var(--radius);
  border-bottom: 1px solid var(--gray-border);
}
.sr-combo-inputs > .sr-combo-inputs-row:last-child input:first-child {
  border-radius: 0 0 0 var(--radius);
  border-bottom: 1px solid var(--gray-border);
}
.sr-combo-inputs > .sr-combo-inputs-row:last-child input:last-child {
  border-radius: 0 0 var(--radius) 0;
  border-bottom: 1px solid var(--gray-border);
}
.sr-combo-inputs > .sr-combo-inputs-row:first-child input:first-child {
  border-radius: var(--radius) 0 0 0;
}
.sr-combo-inputs > .sr-combo-inputs-row:first-child input:last-child {
  border-radius: 0 var(--radius) 0 0;
}
.sr-combo-inputs > .sr-combo-inputs-row:first-child input:only-child {
  border-radius: var(--radius) var(--radius) 0 0;
}
.sr-combo-inputs-row {
  width: 100%;
  display: flex;
}

.sr-combo-inputs-row > input {
  width: 100%;
  border-radius: 0;
}

.sr-combo-inputs-row > input:first-child:not(:only-child) {
  border-right: 0;
}

.sr-combo-inputs-row:not(:first-of-type) .sr-input {
  border-radius: 0 0 var(--radius) var(--radius);
}

/* Buttons and links */
button {
  background: var(--accent-color);
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
button:hover {
  filter: contrast(115%);
}
button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
button:disabled {
  opacity: 0.5;
  cursor: none;
}

.sr-payment-form button,
.fullwidth {
  width: 100%;
}

a {
  color: var(--accent-color);
  text-decoration: none;
  transition: all 0.2s ease;
}

a:hover {
  filter: brightness(0.8);
}

a:active {
  filter: brightness(0.5);
}

/* Code block */
.sr-callout {
  background: var(--gray-offset);
  padding: 12px;
  border-radius: var(--radius);
  max-height: 200px;
  overflow: auto;
}
code,
pre {
  font-family: 'SF Mono', 'IBM Plex Mono', 'Menlo', monospace;
  font-size: 12px;
}

/* Stripe Element placeholder */
.sr-card-element {
  padding-top: 12px;
}

/* Responsiveness */
@media (max-width: 720px) {
  .sr-root {
    flex-direction: column;
    justify-content: flex-start;
    padding: 48px 20px;
    min-width: 320px;
  }

  .sr-header__logo {
    background-position: center;
  }

  .sr-payment-summary {
    text-align: center;
  }

  .sr-content {
    display: none;
  }

  .sr-main {
    width: 100%;
  }
}

/* Pasha styles – Brand-overrides, can split these out */
:root {
  --accent-color: #ed5f74;
  --headline-color: var(--accent-color);
}

.pasha-image-stack {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: auto auto;
}

.pasha-image-stack img,
.pasha-image img {
  border-radius: var(--radius);
  background-color: var(--gray-border);
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  transition: all 0.8s ease;
  opacity: 0;
}

.pasha-image {
  text-align: center;
  margin-top: 20px;
}

.pasha-image img {
  opacity: 1;
}

.pasha-image-stack img:nth-child(1) {
  transform: translate(30px, 15px);
  opacity: 1;
}
.pasha-image-stack img:nth-child(2) {
  transform: translate(-28px, 0px);
  opacity: 1;
}
.pasha-image-stack img:nth-child(3) {
  transform: translate(64px, -50px);
  opacity: 1;
}

/* todo: spinner/processing state, errors, animations */

.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: var(--accent-color);
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: var(--accent-color);
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}
@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Custom */
.container {
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 32px 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 400px;
}

.container .pasha-image-stack {
  margin-top: 20px;
}

.container:first-of-type {
  margin-right: 20px;
}

.quantity-setter {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.quantity-setter input {
  border-radius: 0;
  width: 50px;
  padding: 0;
  text-align: center;
}

.quantity-setter .increment-btn {
  margin-top: 0;
  border-radius: 0;
  border: 1px solid var(--accent-color);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Animated form */

.sr-root {
  animation: 0.4s form-in;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

.sr-payment-form .sr-form-row {
  animation: 0.4s field-in;
  animation-fill-mode: both;
  animation-timing-function: ease;
  transform-origin: 50% 0%;
}

/* need saas for loop :D  */
.sr-payment-form .sr-form-row:nth-child(1) {
  animation-delay: 0;
}
.sr-payment-form .sr-form-row:nth-child(2) {
  animation-delay: 60ms;
}
.sr-payment-form .sr-form-row:nth-child(3) {
  animation-delay: 120ms;
}
.sr-payment-form .sr-form-row:nth-child(4) {
  animation-delay: 180ms;
}
.sr-payment-form .sr-form-row:nth-child(5) {
  animation-delay: 240ms;
}
.sr-payment-form .sr-form-row:nth-child(6) {
  animation-delay: 300ms;
}
.hidden {
  display: none;
}

@keyframes field-in {
  0% {
    opacity: 0;
    transform: translateY(8px) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

@keyframes form-in {
  0% {
    opacity: 0;
    transform: scale(0.98);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

#submit {
  width: 100%;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .glass {
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .glass-dark {
    background: rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .bg-hero {
    background: linear-gradient(180deg, #505050 0%, #000000 89.23%);
    transform: rotate(-180deg);
  }

  .glow-on-hover {
    width: 220px;
    height: 50px;
    outline: none;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 50px;
    font-weight: bold;
  }

  .glow-on-hover:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  .glow-on-hover:before {
    content: '';
    background: linear-gradient(
      45deg,
      rgba(151, 78, 242, 1),
      rgba(255, 177, 113, 1),
      rgba(151, 78, 242, 1)
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 50px;
  }

  .glow-on-hover:active {
    color: #000;
  }

  .glow-on-hover:active:after {
    background: transparent;
  }

  .glow-on-hover:hover:before {
    opacity: 1;
  }

  .glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 50px;
  }

  .glow-on-hover:hover:after {
    background: linear-gradient(
      90deg,
      rgba(151, 78, 242, 1) 0%,
      rgba(255, 177, 113, 1) 100%
    );
    border: solid 1px;
    border-color: #fff;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px !important;
}
.swiper-pagination-bullet {
  width: 17px !important;
  height: 17px !important;
  background: #000000 !important;
  opacity: 0.15 !important;
  border-radius: 50px !important;
}
.swiper-pagination-bullet-active {
  opacity: 1 !important;
}

input[type='range'] {
  height: 26px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: rgb(151, 78, 242);
  background: linear-gradient(
    90deg,
    rgba(151, 78, 242, 1) 0%,
    rgba(255, 177, 113, 1) 100%
  );
  border-radius: 30px;
  border: 0px solid #000000;
}
input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border: 0px solid #000000;
  height: 19px;
  width: 19px;
  border-radius: 50px;
  background: #7151dd;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5.5px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: rgb(151, 78, 242);
  background: linear-gradient(
    90deg,
    rgba(151, 78, 242, 1) 0%,
    rgba(255, 177, 113, 1) 100%
  );
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: rgb(151, 78, 242);
  background: linear-gradient(
    90deg,
    rgba(151, 78, 242, 1) 0%,
    rgba(255, 177, 113, 1) 100%
  );
  border-radius: 30px;
  border: 0px solid #000000;
}
input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border: 0px solid #000000;
  height: 19px;
  width: 19px;
  border-radius: 50px;
  background: #7151dd;
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: rgb(151, 78, 242);
  background: linear-gradient(
    90deg,
    rgba(151, 78, 242, 1) 0%,
    rgba(255, 177, 113, 1) 100%
  );
  border: 0px solid #000000;
  border-radius: 60px;
  box-shadow: 0px 0px 0px #000000;
}
input[type='range']::-ms-fill-upper {
  background: rgb(151, 78, 242);
  background: linear-gradient(
    90deg,
    rgba(151, 78, 242, 1) 0%,
    rgba(255, 177, 113, 1) 100%
  );
  border: 0px solid #000000;
  border-radius: 60px;
  box-shadow: 0px 0px 0px #000000;
}
input[type='range']::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border: 0px solid #000000;
  height: 19px;
  width: 19px;
  border-radius: 50px;
  background: #7151dd;
  cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
  background: rgb(151, 78, 242);
  background: linear-gradient(
    90deg,
    rgba(151, 78, 242, 1) 0%,
    rgba(255, 177, 113, 1) 100%
  );
}
input[type='range']:focus::-ms-fill-upper {
  background: rgb(151, 78, 242);
  background: linear-gradient(
    90deg,
    rgba(151, 78, 242, 1) 0%,
    rgba(255, 177, 113, 1) 100%
  );
}

.fade-in {
  transition: opacity 0.2s ease-in;
}

.fade-in-slow {
  transition: opacity 0.6s ease-in;
}

img.img-transition {
  transition: 0.3s ease-in;
}

@keyframes banner-slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-105%);
  }
}
@keyframes marquee-horizontal {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.sliding-news-banner {
  padding-left: 100%;
  white-space: nowrap;
  /* animation: marquee-horizontal 20s linear infinite; */
  animation: banner-slide-left 20s linear infinite;
}

.dark-rich-text a {
  color: white;
  cursor: pointer;
}

.tab {
  position: absolute;
  width: 150px;
  top: 10px;
  &.left {
    left: 25px;
  }
  &.right {
    right: -25px;
  }
  &.active {
    font-weight: 500;
    color: #7011e4;
  }
}

@media only screen and (min-width: 768px) {
  .sliding-news-banner {
    padding-left: 0;
    animation: '';
  }
}
@media only screen and (max-width: 768px) {
  .splide__pagination {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 1000px) {
  .mb-adjust {
    flex-direction: column;
    align-items: center;

  }
}
